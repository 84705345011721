export const menuItems = [
  {
    label: "3FM Home",
    href: "//www.npo3fm.nl/",
  },
  {
    label: "3FM Serious Request",
    href: "//www.npo3fm.nl/thema/serious-request",
  },
  {
    label: "Kom In Actie",
    href: "//www.npo3fm.nl/kominactie",
  },
];
