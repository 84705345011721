import React, { createRef } from "react";
import {
  Button,
  Card,
  TrackInput,
  Legend,
  Main,
  Recommended,
  Textarea,
  TrackSearch,
} from "../components";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import TopSpin from "../components/TopSpin";

const Track = ({ navigation }) => {
  const selectRef = createRef();
  const { next } = navigation;
  const [state, updateState] = React.useState({
    selectedOption: null,
    options: [],
    cachedTracks: JSON.parse(localStorage.getItem("tracks") || "{}").tracks,
    errorMessage: "",
    motivation: localStorage.getItem("motivation") || "",
    apiTracks: [],
    selectedTrack: {
      ...JSON.parse(
        localStorage.getItem("selectedTrack") ||
          JSON.stringify({ title: "", artist: "", cover: "" })
      ),
    },
    recommendedTracks: JSON.parse(
      localStorage.getItem("recommended_tracks") || "{}"
    ).tracks,
  });

  const handleTrackChoice = (dalet) => {
    const track = state.cachedTracks.find((track) => track.dalet === dalet);

    if (!track) {
      return;
    }

    localStorage.setItem("selectedTrack", JSON.stringify(track));

    updateState({
      ...state,
      selectedTrack: track,
      errorMessage: "",
    });
  };

  const nextStep = () => {
    const track = JSON.parse(localStorage.getItem("selectedTrack"));

    if (!track) {
      updateState({
        ...state,
        errorMessage: "Geen plaat geselecteerd of opgegeven",
      });

      return;
    }

    localStorage.setItem(
      "motivation",
      document.getElementById("motivation").value
    );

    TopSpin.doClick({
      name: "volgende-knop",
      type: "navigation",
      chapter_1: "Gegevens invullen",
    });

    next();

    document.getElementById("steps").scrollIntoView({
      behavior: "smooth",
    });
  };

  const removeTrack = () => {
    localStorage.removeItem("selectedTrack");
    localStorage.removeItem("motivation");
    updateState({
      ...state,
      selectedTrack: {
        title: "",
        artist: "",
        cover: "",
      },
      motivation: "",
    });

    if (selectRef.current?.select?.select) {
      selectRef.current.select.select.clearValue();
    }
  };

  const handleSelectTrack = (track) => {
    if (!track) {
      return;
    }

    localStorage.setItem("selectedTrack", JSON.stringify(track));

    updateState({
      ...state,
      selectedTrack: {
        title: track.title,
        artist: track.artist,
        cover: track.cover,
      },
      errorMessage: "",
    });
  };

  const checkInput = (data) => {
    if (data.target.value.length > 0) {
      updateState({
        ...state,
        errorMessage: "",
      });
    }
  };

  return (
    <>
      <Main.Container>
        <Main.Row>
          <Main.Column>
            <p>
              Geweldig dat je een plaat wil aanvragen voor 3FM Serious Request.
              Kies eerst jouw favoriete nummer en daarna word je doorverwezen
              naar Metakids voor je donatie. Alvast heel erg bedankt namens 3FM
              en Metakids.
            </p>
          </Main.Column>
        </Main.Row>
        <Main.Row>
          <Main.Column>
            <TrackSearch
              selectRef={selectRef}
              onSelectTrack={handleSelectTrack}
            />

            <TrackInput onAddTrack={handleSelectTrack} />

            {state.selectedTrack.title.length > 0 ? (
              <form>
                <div>
                  <hr />
                  <StyledChosenTrack>
                    <Main.Column size={{ md: 1 / 3 }}>
                      <Card
                        title={state.selectedTrack.title}
                        artist={state.selectedTrack.artist}
                        image={state.selectedTrack.cover}
                        buttonToggle="remove"
                        removeTrack={removeTrack}
                      />
                    </Main.Column>
                    <Main.Column size={{ md: 2 / 3 }}>
                      <Legend htmlFor="motivation">Jouw motivatie</Legend>
                      <Textarea
                        id="motivation"
                        name="motivation"
                        placeholder="Waarom heb je voor dit nummer gekozen voor 3FM Serious Request?"
                        defaultValue={state.motivation}
                        onChange={checkInput}
                      />
                    </Main.Column>
                  </StyledChosenTrack>
                </div>
              </form>
            ) : null}

            {state.errorMessage && (
              <div>
                <h3 className="error"> {state.errorMessage} </h3>
              </div>
            )}

            <Main.Text center>
              <Button.Green onClick={nextStep}>VOLGENDE</Button.Green>
            </Main.Text>
          </Main.Column>
        </Main.Row>
      </Main.Container>

      <Main.BackgroundBlur>
        <Main.Container wide>
          <Main.Row marginvertical="true">
            {state.recommendedTracks ? (
              <Recommended
                clickhandler={handleTrackChoice}
                tracks={state.recommendedTracks}
              />
            ) : null}
          </Main.Row>
        </Main.Container>
      </Main.BackgroundBlur>
    </>
  );
};

const StyledChosenTrack = styled(Main.Row)`
  > * + * {
    margin-top: 1.5em;

    ${breakpoint("md")`
			margin-top: 0;
			padding-left: 1.5em;
		`}
  }
`;

export default Track;
