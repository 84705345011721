import React from "react";
import styled from "styled-components";

export default function MultipleChoice({ children, ...restProps }) {
  return <StyledMultipleChoice {...restProps}>{children}</StyledMultipleChoice>;
}

const StyledMultipleChoice = styled.div.attrs((props) => ({
  className: props.button === "true" ? "button" : null,
}))`
  display: ${(props) => (props.button === "true" ? null : "block")};
  position: relative;
  padding: ${(props) => (props.button === "true" ? "0 0 0 0" : "0 0 0 1.5em")};
  min-height: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  input {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 1.5em;
    height: 1.5em;
    z-index: 1;
    margin: 0;
    opacity: 0;
  }

  &.button {
    label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      min-width: 0;
      padding: ${({ theme }) => theme.spacing.S}
        ${({ theme }) => theme.spacing.M};
      min-height: 1.5em;
      touch-action: manipulation;
      overflow: hidden;
      font-size: ${({ variant }) => (variant === "primary" ? "1.35em" : "1em")};
      font-weight: 700;
      line-height: 1;
      color: ${({ variant, theme }) =>
        variant === "primary" ? theme.form.light : theme.form.dark};
      text-decoration: none;
      white-space: normal;
      vertical-align: middle;
      background-color: ${({ variant, theme }) =>
        variant === "primary" ? theme.colors.green : theme.form.light};
      border: ${({ variant, theme }) =>
        variant === "primary" ? theme.form.border : theme.form.border};
      border-color: ${({ theme }) => theme.form.dark};
      border-radius: 0;
      transition: color 200ms cubic-bezier(0.195, 0.4, 0.45, 0.785);
    }

    input:checked + label {
      color: ${({ variant, theme }) =>
        variant === "primary" ? theme.form.light : theme.colors.black};
      border-color: ${({ theme }) => theme.colors.green};
      background-color: ${({ variant, theme }) =>
        variant === "primary" ? theme.colors.green : theme.colors.green};
    }

    input:focus + label {
      border-color: ${({ theme }) => theme.colors.focus};
    }
  }

  &:not(.button) {
    label {
      cursor: pointer;
      padding: 0.25em 1em;
      display: flex;
      align-items: center;
      min-height: 1.5em;
      touch-action: manipulation;

      a {
        color: inherit;
        display: inline-block;
        font-weight: 700;
      }
    }

    [type="checkbox"] + label::before,
    [type="radio"] + label::before {
      content: "";
      border: 0.125em solid;
      background: 0 0;
      width: 1.5em;
      height: 1.5em;
      flex: 0 0 1.5em;
      position: absolute;
      top: calc(50% - 0.75em);
      left: 0;
      transition: 0.2s cubic-bezier(0.195, 0.4, 0.45, 0.785);
    }

    [type="checkbox"] + label::after,
    [type="radio"] + label::after {
      content: "";
      border: 0.75em solid;
      position: absolute;
      opacity: 0;
      transform: scale(0.5);
      transition: 0.2s cubic-bezier(0.195, 0.4, 0.45, 0.785);
    }

    [type="radio"] + label::before {
      border-radius: 50%;
    }

    [type="radio"] + label::after {
      width: 0;
      height: 0;
      top: 0.5em;
      left: 0.5em;
      border-radius: 50%;
    }

    [type="checkbox"] + label::after {
      border-width: 0 0 0.25em 0.25em;
      background: 0 0;
      width: 1em;
      height: 0.5em;
      top: calc(50% - 0.3em);
      left: 0.25em;
      transform: rotate(-45deg) scale(0.5);
    }

    input:checked + label::before {
      border-color: ${({ theme }) => theme.colors.green};
    }

    input:checked + label::after {
      border-color: ${({ theme }) => theme.colors.green};
      opacity: 1;
      transform: rotate(-45deg) scale(1);
    }

    [type="radio"]:checked + label {
      color: ${({ theme }) => theme.colors.green};
    }

    [type="checkbox"]:focus + label::before,
    [type="radio"]:focus + label::before {
      box-shadow: 0 0 0 0.125em #febd22;
    }
  }

  input:disabled {
    opacity: 0;
    + label {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  p {
    margin-bottom: 0;
  }
`;
