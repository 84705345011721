import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function Steps({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>;
}

Steps.List = function StepsList({ children, ...restProps }) {
  return <List {...restProps}>{children}</List>;
};

Steps.ListItem = function StepsListItem({ children, ...restProps }) {
  return <ListItem {...restProps}>{children}</ListItem>;
};

Steps.StepsLink = function StepsStepsLink({ children, ...restProps }) {
  return <StepsLink {...restProps}>{children}</StepsLink>;
};

const Wrapper = styled.nav`
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing.M};
  padding-bottom: ${({ theme }) => theme.spacing.M};
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  position: relative;
  width: 1em;
  height: 1em;

  &:after {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    width: 1em;
    height: 1em;
    background: ${(props) => props.theme.colors.green};
    border-radius: 50%;
    opacity: 0.5;
    border: 3px solid transparent;
    transition: all 300ms ease-in-out;
  }

  &.active {
    &:after {
      opacity: 1;
      border-color: ${(props) => props.theme.colors.green};
      box-shadow: 0px 0px 0px 3px ${(props) => props.theme.colors.greenType},
        0px 0px 0px 5px ${(props) => props.theme.colors.green};
    }
  }

  + li {
    margin-left: 0.75em;
  }
`;

const StepsLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
`;
