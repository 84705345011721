import React from "react";
import { Button, Main } from "../components";

const ThankYou = () => {
  const [state, updateState] = React.useState({
    selectedTrack: JSON.parse(localStorage.getItem("selectedTrack") || null),
    contribution: localStorage.getItem("contribution") || null,
    errorMessage: "",
    trackTitle: "",
  });

  React.useEffect(() => {
    let trackTitle = null;

    if (state.selectedTrack?.artist && state.selectedTrack?.title) {
      trackTitle = `${state.selectedTrack.artist} - ${state.selectedTrack.title}`;
    }

    updateState({
      ...state,
      trackTitle,
      selectedTrack: null,
    });

    localStorage.removeItem("selectedTrack");
    localStorage.removeItem("motivation");
  }, []);

  let whatsappShareText =
    "Hi, ik heb een nummer aangevraagd voor 3FM Serious Request en steun daarmee Metakids! Strijd mee tegen de meest dodelijke ziekte onder kinderen door ook een plaat aan te vragen via http://3fm.nl/plaat.";
  let twitterShareText =
    "Hi, ik heb een nummer aangevraagd voor 3FM Serious Request en steun daarmee Metakids! Strijd mee tegen de meest dodelijke ziekte onder kinderen door ook een plaat aan te vragen via http://3fm.nl/plaat.\n\n";

  if (state.trackTitle) {
    whatsappShareText = `Hi, ik heb ${state.trackTitle} aangevraagd voor 3FM Serious Request en steun daarmee Metakids! Strijd mee tegen de meest dodelijke ziekte onder kinderen door ook een plaat aan te vragen via http://3fm.nl/plaat.`;
    twitterShareText = `Hi, ik heb ${state.trackTitle} aangevraagd voor 3FM Serious Request en steun daarmee Metakids! Strijd mee tegen de meest dodelijke ziekte onder kinderen door ook een plaat aan te vragen via http://3fm.nl/plaat.\n\n`;
  }

  return (
    <>
      <Main.Container>
        <Main.Row $variant="withSpacingBorderLeft">
          <Main.Column size={{ md: state.trackTitle ? 1 / 2 : 1 }}>
            <Main.Title level={2}>Bedankt voor je bijdrage</Main.Title>
            <Main.Text>
              We doen ons best om je aangevraagde plaat op de radio te draaien
              tijdens 3FM Serious Request 2024 en zorgen dat je donatie op de
              juiste plek terechtkomt. Meer weten over wat Metakids met het
              opgehaalde bedrag kan doen? Dat lees je{" "}
              <a href="http://3fm.nl/doel">hier</a>.
            </Main.Text>
            <Main.Text>
              Je ontvangt binnen 24 uur een bevestiging van je donatie in je
              mail.
            </Main.Text>
          </Main.Column>
          {state.trackTitle && (
            <Main.Column size={{ md: 1 / 2 }}>
              <Main.Title level={2}>Jouw aangevraagde nummer</Main.Title>
              <Main.Text>
                {state.trackTitle}
                {state.contribution}
              </Main.Text>
            </Main.Column>
          )}
        </Main.Row>
        <Main.Row>
          <Main.Column>
            <Main.Text center>
              <Main.Anchor
                href={`https://wa.me?text=${encodeURIComponent(
                  whatsappShareText
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button.Green>Deel via WhatsApp</Button.Green>
              </Main.Anchor>
              <br />
              <br />
              <Main.Anchor
                href={`https://x.com/intent/tweet?text=${encodeURIComponent(
                  twitterShareText
                )}&hashtags=3FM,SR24,Metakids,GlazenHuis&via=3fm`}
                target="_blank"
                rel="noreferrer"
              >
                <Button.Green>Deel via X</Button.Green>
              </Main.Anchor>
              <br />
              <br />
              <Main.Anchor
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  "https://www.3fm.nl/plaat"
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button.Green>Deel via Facebook</Button.Green>
              </Main.Anchor>
              <br />
              <br />
              <Main.Anchor href="https://www.npo3fm.nl/thema/serious-request">
                <Button.Green>Meer 3FM SR24</Button.Green>
              </Main.Anchor>
            </Main.Text>
          </Main.Column>
        </Main.Row>
      </Main.Container>
    </>
  );
};

export default ThankYou;
