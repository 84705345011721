import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export default function List(props) {
  const { children, ...restProps } = props;
  return <StyledList {...restProps}>{children}</StyledList>;
}

const StyledList = styled.ul`
  padding: 0;
  margin-bottom: 0;
  list-style: none;

  &.recommended {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-top: 0.5em;

    ${breakpoint("md")`
      flex-wrap: nowrap;
    `}
  }
`;

List.Item = function ListItem(props) {
  const { children, ...restProps } = props;
  return <StyledListItem {...restProps}>{children}</StyledListItem>;
};

const StyledListItem = styled.li`
  flex: 1 0 100%;

  ${breakpoint("md")`
   flex: 1 1 25%;
  `}

  + li {
    margin-top: 0.5em;

    ${breakpoint("md")`
   	  margin-top: 0;
   	  margin-left: 1.5em;
    `}
  }
`;

List.ItemVert = function ListItemVert(props) {
  const { children, ...restProps } = props;
  return <StyledListItemVert {...restProps}>{children}</StyledListItemVert>;
};

const StyledListItemVert = styled.li`
  + li {
    margin-top: 0.5em;
  }
`;
