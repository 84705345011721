import React from "react";
import { bool, func } from "prop-types";
import styled from "styled-components";

const Backdrop = ({ open, setOpen, ...props }) => {
  const isExpanded = !!open;

  return (
    <StyledBackdrop
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <span />
      <span />
      <span />
    </StyledBackdrop>
  );
};

const StyledBackdrop = styled.div`
  position: fixed;
  z-index: 1;
  width: ${({ open }) => (open ? "100%" : "0")};
  height: ${({ open }) => (open ? "100%" : "0")};
  left: 0;
  top: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  opacity: ${({ open }) => (open ? "1" : "0")};
  backdrop-filter: blur(3px);
  transition: opacity 0.4s cubic-bezier(0.195, 0.4, 0.45, 0.785) 0s;
`;

Backdrop.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Backdrop;
