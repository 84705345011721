import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Icon, Link } from "../components";

/**
 * ToDo: zijn de Social Follow links correct?
 * ToDo: zijn de Social Share links correct?
 *
 */

const socialFollow = [
  {
    icon: "twitter",
    pathname: "https://twitter.com/3fm",
  },
  {
    icon: "facebook",
    pathname: "https://www.facebook.com/radio3fm",
  },
  {
    icon: "youtube",
    pathname: "https://www.youtube.com/user/3fm/",
  },
  {
    icon: "instagram",
    pathname: "https://www.instagram.com/npo3fm",
  },
  {
    icon: "spotify",
    pathname: "https://open.spotify.com/user/3fm",
  },
];
const socialShare = [
  {
    icon: "twitter",
    pathname: "https://twitter.com/3FM",
  },
  {
    icon: "facebook",
    pathname: "https://www.facebook.com/radio3fm/",
  },
  {
    icon: "whatsapp",
    pathname: "https://www.facebook.com/radio3fm/",
  },
];

const SocialLinks = (props) => {
  let socialList = props.type === "follow" ? socialFollow : socialShare;

  return (
    <StyledList type={props.type}>
      {socialList.map((item, index) => (
        <StyledListItem key={index}>
          <StyledLink
            to={{
              pathname: item.pathname,
            }}
            target="_blank"
          >
            <StyledIcon icon={item.icon} />
          </StyledLink>
        </StyledListItem>
      ))}
    </StyledList>
  );
};

const StyledList = styled.ul`
  display: flex;
  justify-content: ${(props) =>
    props.type === "follow" ? "center" : "flex-start"};
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  font-size: 1.5rem;

  ${breakpoint("md")`
     text-align: center;
  `}
`;

const StyledListItem = styled.li`
  display: inline-block;
  vertical-align: middle;

  + * {
    margin-left: 0.75em;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  transition: color 0.2s cubic-bezier(0.195, 0.4, 0.45, 0.785) 0s;

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;

const StyledIcon = styled(Icon)`
  width: auto;
  user-select: none;
`;

export default SocialLinks;
