import React from "react";
import { Donation, ThankYou, Track } from "./";
import TopSpin from "../components/TopSpin";

const Home = (props) => {
  React.useEffect(() => {
    TopSpin.doCount("Stap" + props.id, {});
  });

  return (
    <>
      {props.id === 1 && <Track {...props} />}
      {props.id === 2 && <Donation {...props} />}
      {props.id === 3 && <ThankYou {...props} />}
    </>
  );
};

export default Home;
