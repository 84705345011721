import React from "react";
import { Header, Logo, Toolbar, CharityLogo } from "../components";

export function HeaderContainer(props) {
  return (
    <Header>
      <Toolbar />
      <Header.Image>
        <Header.Sizer>
          <Header.Container>
            <Header.Row>
              <Header.TagLine>
                Het Glazen Huis | 18 - 24 december | Rodetorenplein, Zwolle
              </Header.TagLine>
              <Header.LogoWrapper>
                <Header.HeaderLink
                  to="//www.npo3fm.nl/seriousrequest"
                  target="_blank"
                  className="logo"
                >
                  <Logo />
                </Header.HeaderLink>
                <CharityLogo />
              </Header.LogoWrapper>
            </Header.Row>
            <Header.Row>
              <Header.Column>
                <Header.Steps>stap {props.id}/3</Header.Steps>
                <Header.Title>
                  Vraag nu <Header.Highlight>jouw plaat</Header.Highlight> aan!
                </Header.Title>
              </Header.Column>
            </Header.Row>
          </Header.Container>
        </Header.Sizer>
      </Header.Image>
    </Header>
  );
}

export default HeaderContainer;
