import React, { forwardRef } from "react";
import styled from "styled-components";
import {
  ConditionalWrapper,
  MultipleChoice,
  VisuallyHidden,
} from "../components";

const Radio = forwardRef((props, ref) => {
  return (
    <>
      <ConditionalWrapper
        condition={props.display}
        wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
      >
        <ConditionalWrapper
          condition={props.highlight}
          wrapper={(children) => (
            <Highlight>
              <span>Meest gekozen</span>
              {children}
            </Highlight>
          )}
        >
          <MultipleChoice button={props.button} variant={props.variant}>
            <input
              id={props.id}
              type="radio"
              name={props.name}
              value={props.value}
              onChange={props.onChange}
              required={props.required}
              ref={ref}
            />
            <label htmlFor={props.id}>{props.children}</label>
          </MultipleChoice>
        </ConditionalWrapper>
      </ConditionalWrapper>
    </>
  );
});

const Highlight = styled.div`
  background: hsl(115, 69%, 49%, 50%);
  text-align: center;

  span {
    font-family: ${({ theme }) => theme.type.fontFamily};
    font-size: 0.75rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    padding-bottom: 5px;
  }
`;

export default Radio;
