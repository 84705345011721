import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

export function Form(props) {
  const { defaultValues, children, onSubmit } = props;
  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {React.Children.map(children, (child) => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                key: child.props.name,
              },
            })
          : child;
      })}
    </form>
  );
}

export function Fieldset(props) {
  const { name, ...rest } = props;
  return <StyledFieldset id={name} {...rest} />;
}

const StyledFieldset = styled.fieldset`
  padding: 0;
  margin: 0;
  border: none;

  ${(props) =>
    props.variant === "donatie" &&
    `
    > * + * {
      margin-top: .75em;
    }

    .flexwrapper {
	  display: flex;
	  flex-wrap: wrap;
	  align-items: flex-end;

	  > *:not(label) {
	    margin-top: .75em;
	    flex: 1 0 calc(15% - .75em);
	  }

	  > * + * {
	    margin-left: .75em;

	    &:first-of-type {
	      margin-left: 0;
	    }
	  }
	`}

  ${(props) =>
    props.variant === "personal" &&
    `
    > * + * {
      margin-top: .75em;
    }

    .flexwrapper {
    position: relative;
	  display: flex;
	  flex-wrap: wrap;

	  > *:not(label) {
	    margin-top: .75em;
	    flex: 1 1 calc(15% - .75em);
	  }

	  > * + div {
	    margin-left: .75em;

	    &:first-of-type {
	      margin-left: 0;
	    }
	  }

	  .button label {
      padding-left: .25em;
      padding-right: .25em;
	  }

	  span {
	    z-index: 2;
	    top: 0;
	  }
	`}
`;

export function Legend(props) {
  const { name, ...rest } = props;
  return <StyledLegend htmlFor={name} {...rest} />;
}

const StyledLegend = styled.legend`
  flex-basis: 100%;
  color: rgb(11, 17, 22);
  font-family: ${({ theme }) => theme.type.fontFamily};
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-transform: uppercase;
`;

export function Label(props) {
  const { name, ...rest } = props;
  return <StyledLabel htmlFor={name} {...rest} />;
}

const StyledLabel = styled.label`
  flex-basis: 100%;
    margin-top: 0.375em;
  }

  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  white-space: nowrap;

  a {
    font-weight: 700;
  }
`;

export function Select(props) {
  const { register, options, name, ...rest } = props;
  return (
    <StyledSelect name={name} ref={register} {...rest}>
      {options.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled.select`
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 1px solid ${({ theme }) => theme.form.light};
    -webkit-text-fill-color: ${({ theme }) => theme.form.light};
    -webkit-box-shadow: 0 0 0px 30px ${({ theme }) => theme.form.dark} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export function Textarea(props) {
  const { register, name, ...rest } = props;
  return <StyledTextarea name={name} ref={register} {...rest} />;
}

const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 14rem;
  color: ${({ theme }) => theme.form.dark};
  background-color: ${({ theme }) => theme.form.light};
  font-family: ${({ theme }) => theme.type.fontFamily};
  font-size: 16px;
  line-height: 26px;
  border: ${({ theme }) => theme.form.border};
  border-color: ${({ theme }) => theme.form.dark};
  border-radius: 0;
  padding: 0.5em;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 1px solid ${({ theme }) => theme.form.light};
    -webkit-text-fill-color: ${({ theme }) => theme.form.light};
    -webkit-box-shadow: 0 0 0px 30px ${({ theme }) => theme.form.dark} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export function FormError(props) {
  const { ...rest } = props;
  return <StyledFormError {...rest} />;
}

const StyledFormError = styled.span.attrs((props) => ({
  role: props.role || "alert",
}))`
  color: red;
`;
