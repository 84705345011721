import React from "react";
import styled from "styled-components";

export default function VisuallyHidden({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>;
}

const Wrapper = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  whitespace: nowrap;
  wordwrap: normal;
`;
