import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { bool } from "prop-types";
import { menuItems } from "../constants/menuItems";

const Menu = ({ open, ...props }) => {
  const isHidden = !!open;
  const tabIndex = isHidden ? 0 : -1;

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <MenuContent>
        <MenuItems>
          {menuItems.map((item, index) => (
            <MenuItem key={index}>
              <MenuLink key={item.label} href={item.href} tabIndex={tabIndex}>
                {item.label}
              </MenuLink>
            </MenuItem>
          ))}
        </MenuItems>
      </MenuContent>
    </StyledMenu>
  );
};

const StyledMenu = styled.nav`
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: hsl(0, 0%, 100%);
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100vh;
  text-align: left;
  padding: ${({ theme }) => theme.spacing.M};
  top: 0;
  left: 0;
  transition: transform 300ms ease-in-out;

  ${breakpoint("sm")`
    padding: ${({ theme }) => theme.spacing.XL};
  `}
`;

const MenuContent = styled.div``;

const MenuItems = styled.ul`
  margin: 0;
  padding: 0.75rem;
  list-style: none;
  color: hsl(0, 0%, 0%);
  background: hsl(0, 0%, 100%);
`;

const MenuItem = styled.li`
  + li {
    margin-top: 0.1875em;
  }
`;

const MenuLink = styled.a`
  position: relative;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.XS} ${({ theme }) => theme.spacing.S};
  color: ${(props) =>
    props.children !== "Serious Request"
      ? "hsl(0, 0%, 0%)"
      : "hsl(0, 0%, 100%)"};
  background-color: ${(props) =>
    props.children !== "Serious Request" ? "transparent" : "hsl(0, 0%, 0%)"};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  transform: translateZ(0);
  backface-visibility: visible;
  transition: 200ms cubic-bezier(0.195, 0.4, 0.45, 0.785);

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    color: ${(props) =>
      props.children !== "Serious Request"
        ? "hsl(0, 0%, 0%)"
        : "hsl(0, 0%, 100%)"};
    background-color: ${(props) =>
      props.children !== "Serious Request"
        ? "hsl(0, 0%, 90%)"
        : "hsl(0, 0%, 0%)"};
    opacity: 0;
    transform: scale(0.66) rotate(0deg);
    transition: 200ms cubic-bezier(0.195, 0.4, 0.45, 0.785);
  }

  &:hover {
    background-color: transparent;

    &::before {
      opacity: 1;
      transform: scale(1) rotate(-2deg);
    }
  }
`;

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
