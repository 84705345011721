import React from "react";

import { ThemeProvider } from "styled-components";
import theme from "./theme";
import { GlobalStyles } from "./global-styles";
import { BrowserRouter as Router } from "react-router-dom";
import { FooterContainer, HeaderContainer } from "./containers";
import { Home } from "./views";
import { useStep } from "react-hooks-helper";
import StepsContainer from "./containers/Steps";
import { Main } from "./components";

const steps = [{ id: 1 }, { id: 2 }, { id: 3 }];

const App = () => {
  let { step, navigation } = useStep({ initialStep: 0, steps });

  if (window.location.pathname === "/thankyou") {
    step = { id: 3 };
  }

  const { id } = step;

  const props = { navigation, id };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <HeaderContainer {...props} />
        <Main>
          <StepsContainer id={props.id} />
          <Home {...props} />
        </Main>
        <FooterContainer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
