import React, { forwardRef } from "react";
import { ConditionalWrapper, FormError, MultipleChoice } from "../components";

const CheckBox = forwardRef((props, ref) => {
  return (
    <MultipleChoice>
      <input
        id={props.name}
        type="checkbox"
        name={props.name}
        onChange={props.onChange}
        ref={ref}
      />
      <label htmlFor={props.name}>{props.children}</label>

      <ConditionalWrapper
        condition={props.errors}
        wrapper={() => <FormError>{props.errors.message}</FormError>}
      >
        {""}
      </ConditionalWrapper>
    </MultipleChoice>
  );
});

export default CheckBox;
