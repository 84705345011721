import React from "react";
import styled from "styled-components";
import { Icon } from "./index";

const Tooltip = (props) => (
  <StyledTooltip content={props.content} role="tooltip">
    <Icon icon="info" />
  </StyledTooltip>
);

const StyledTooltip = styled.span`
  position: absolute;
  right: 15px;
  top: 45px;
  cursor: help;

  &::after {
    content: attr(content);
    position: absolute;
    top: 0;
    right: 1.5em;
    width: 16em;
    max-width: 16em;
    height: auto;
    padding: 0.375em;
    white-space: pre-wrap;
    pointer-events: none;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.type};
    background-color: ${({ theme }) => theme.colors.typeInverse};
    box-shadow: 0 0 1em 0 hsla(0, 0%, 0%, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in-out 0s;
  }

  &:hover {
    &::after {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
`;

export default Tooltip;
