import React from "react";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

export default function Button({ children, ...restProps }) {
  return (
    <DefaultButton {...restProps}>
      <ButtonTextWrapper>{children}</ButtonTextWrapper>
    </DefaultButton>
  );
}

const buttonResetCSS = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  box-sizing: border-box;
`;

const focusCSS = css`
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: outline-color 200ms;

  &:focus-visible {
    outline-color: ${({ theme }) => theme.colors.focus};
  }
`;

const buttonBaseCSS = css`
  ${buttonResetCSS}
  ${focusCSS}

  align-items: center;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.type.fontFamily};
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  line-height: 1.4;
  padding: 0 16px;
  position: relative;
  text-transform: none;
  transition: outline-color 200ms, background-color 200ms, color 200ms;

  @media (min-width: 600px) {
    font-size: 18px;
  }
`;

const defaultButtonCSS = css`
  ${buttonBaseCSS}
  background: linear-gradient(150.82deg, rgb(255, 255, 40) -9.11%, rgb(53, 211, 39) 92.16%);

  &::before {
    background: linear-gradient(
      150.82deg,
      rgb(255, 255, 40) -9.11%,
      rgb(53, 211, 39) 92.16%
    );
    border-radius: 5px;
    box-sizing: initial;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
    transition: padding 200ms, left 200ms, top 200ms;
    width: 100%;
    padding: 0;
    z-index: -1;
  }

  &:hover {
    &:before {
      left: -10px;
      padding: 10px;
      top: -10px;
    }
  }
`;

const DefaultButton = styled.button`
  ${defaultButtonCSS}
  min-height: 44px;
`;

const ButtonTextWrapper = styled.span`
  align-items: center;
  column-gap: 8px;
  display: flex;
  justify-content: center;
  position: relative;
`;

/**
 * Styled Button with an icon
 */

Button.Icon = function ButtonIcon(props) {
  const { children, ...restProps } = props;
  return <Button {...restProps}>{children}</Button>;
};

/**
 * Styled Button with an add/remove icon
 */

Button.AddRemove = function ButtonAddRemove(props) {
  const { children, ...restProps } = props;
  return (
    <StyledButtonAddRemove {...restProps}>{children}</StyledButtonAddRemove>
  );
};

const StyledButtonAddRemove = styled(Button)`
  padding: 0.5em;
  font-size: 0.75em;
  width: 44px;
  transition: transform 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.type};
  background-color: ${({ theme }) => theme.colors.typeInverse};

  ${breakpoint("md")`
  	color: hsl(0, 0%, 0%);
    background-color: hsl(0, 0%, 100%);
  `}

  svg {
    float: none;

    ${(props) =>
      props.variant === "add" &&
      `
	transform: rotate(45deg);
	`}
  }
`;

/**
 * Styled Button with green background
 */

Button.Green = function ButtonGreen(props) {
  const { children, ...restProps } = props;
  return <Button {...restProps}>{children}</Button>;
};

/**
 * Styled Button with just text
 */

Button.Text = function ButtonText(props) {
  const { children, ...restProps } = props;
  return (
    <StyledButtonText {...restProps} type="button">
      {children}
    </StyledButtonText>
  );
};

const StyledButtonText = styled.button`
  color: ${({ theme }) => theme.colors.type};
  background-color: transparent;
  text-transform: lowercase;
  font-weight: 400;
  border: none;

  &:first-letter {
    text-transform: uppercase;
  }

  &:hover {
    cursor: pointer;
  }

  &,
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

Button.Group = function ButtonGroup(props) {
  const { children, ...restProps } = props;
  return <StyledButtonGroup {...restProps}>{children}</StyledButtonGroup>;
};

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  ${breakpoint("sm")`
    flex-direction: row;
  `}
`;
