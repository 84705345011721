import React from "react";
import { Steps, VisuallyHidden } from "../components";

const StepsContainer = (props) => {
  const steps = [
    { step: 1, content: "Stap 1" },
    { step: 2, content: "Stap 2" },
    { step: 3, content: "Stap 3" },
  ];

  return (
    <Steps id="steps">
      <Steps.List>
        {steps.map((item, key) => (
          <Steps.ListItem
            key={key}
            className={props.id === item.step ? "active" : ""}
          >
            <VisuallyHidden>{item.content}</VisuallyHidden>
          </Steps.ListItem>
        ))}
      </Steps.List>
    </Steps>
  );
};

export default StepsContainer;
