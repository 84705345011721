import React from "react";
import { Footer, Logo, SocialLinks } from "../components";

export function FooterContainer() {
  return (
    <Footer>
      <Footer.Container>
        <Footer.Row>
          <Footer.Column>
            <Footer.FooterLink
              href="https://www.npo3fm.nl/seriousrequest"
              className="logo"
            >
              <Logo />
            </Footer.FooterLink>
          </Footer.Column>
        </Footer.Row>
        <Footer.Row $variant="withSpacingBorderLeft" className="footer--middle">
          <Footer.Column size={{ sm: 1 / 2, md: 1 / 4 }}>
            <Footer.Title level={2} light>
              De actie
            </Footer.Title>
            <Footer.List>
              <Footer.ListItem>
                <Footer.FooterLink href="https://www.npo3fm.nl/thema/serious-request">
                  Over #SR24
                </Footer.FooterLink>
              </Footer.ListItem>
              <Footer.ListItem>
                <Footer.FooterLink
                  href="https://metakids.nl/"
                  target="_blank"
                  rel="noopener"
                >
                  Metakids
                </Footer.FooterLink>
              </Footer.ListItem>
              <Footer.ListItem>
                <Footer.FooterLink href="https://www.npo3fm.nl/veelgestelde-vragen-over-3fm-serious-request">
                  Veelgestelde vragen
                </Footer.FooterLink>
              </Footer.ListItem>
            </Footer.List>
          </Footer.Column>
          <Footer.Column size={{ sm: 1 / 2, md: 1 / 4 }}>
            <Footer.Title level={2} light>
              Doneer
            </Footer.Title>
            <Footer.List>
              <Footer.ListItem>
                <Footer.FooterLink href="https://www.npo3fm.nl/doneer">
                  Alle doneeropties
                </Footer.FooterLink>
              </Footer.ListItem>
            </Footer.List>
          </Footer.Column>
          <Footer.Column size={{ md: 2 / 4 }}>
            <Footer.Title level={2} svgIcon light>
              Metakids
            </Footer.Title>
            <Footer.Text>
              IBAN NL70 ABNA 0822 405652
              <br />
              t.n.v. &apos;Metakids&apos;
              <br />
              o.v.v. &apos;3FM Serious Request&apos;
            </Footer.Text>
          </Footer.Column>
        </Footer.Row>
        <Footer.Row halign="justify">
          <Footer.Column size={{ md: 1 / 3 }}>
            <Footer.FooterLink href="https://over.npo.nl/organisatie/privacy-en-security">
              Voorwaarden & Privacybeleid
            </Footer.FooterLink>
          </Footer.Column>
          <Footer.Column $variant="socialinks" size={{ md: 1 / 3 }}>
            <Footer.Div>
              <SocialLinks type="follow" links color="white" />
            </Footer.Div>
          </Footer.Column>
          <Footer.Column size={{ md: 1 / 3 }} className="inline">
            <Footer.FooterLink href="#" className="npo_cc_settings_link">
              Cookiebeleid
            </Footer.FooterLink>
          </Footer.Column>
        </Footer.Row>
      </Footer.Container>
    </Footer>
  );
}

export default FooterContainer;
