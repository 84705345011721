import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

const AmountOption = forwardRef(
  ({ amount, description, highlight, id, name, onChange, selected }, ref) => (
    <Container selected={selected} highlight={highlight} htmlFor={id}>
      <Information>
        <AmountIndicator>
          <DonateText>Doneer</DonateText>
          <Amount>€ {amount}</Amount>
        </AmountIndicator>
        <Explanation>
          <LightText>{description}</LightText>
        </Explanation>
      </Information>
      <Footer selected={selected}>
        <Radio>
          <input
            type="radio"
            id={id}
            name={name}
            value={amount}
            onChange={onChange}
            ref={ref}
          />
          <span>
            {selected ? `Ik doneer €${amount}` : `Kies en doneer €${amount}`}
          </span>
        </Radio>
      </Footer>
    </Container>
  )
);

AmountOption.propTypes = {
  amount: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  selected: PropTypes.bool,
  description: PropTypes.string,
};

const Container = styled.label`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.type};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.type.fontFamily};
  font-size: 14px;
  font-stretch: normal;
  font-weight: 500;
  padding: 16px;
  position: relative;
  row-gap: 16px;
  transition: box-shadow 200ms ease-in-out;
  width: 100%;
  border: solid 2px ${({ theme }) => theme.colors.black};
  border-radius: 3px;

  margin-top: ${({ highlight }) => (highlight ? "26px" : "0px")};

  ${breakpoint("md")`
      margin-top: 0px;
  `};

  ${({ highlight, theme }) =>
    highlight &&
    `
    &::before {
      color: ${theme.colors.type};
      content: 'Meest gekozen';
      font-weight: 900;
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      transform: translateY(-100%);
      width: 100%;
      border: solid 2px ${theme.colors.type};
      border-radius: 3px 3px 0 0;
      box-sizing: content-box;
      margin-left: -2px;
    }
  `};

  ${breakpoint("md")`
    align-items: center;
  `}
`;

const Information = styled.div`
  display: flex;
  gap: 12px;
  justify-content: start;

  ${breakpoint("md")`
    flex-direction: column;
    justify-items: center;
  `}
`;

const AmountIndicator = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  line-height: 0.7;

  > span {
    &::before,
    &::after {
      content: "";
      display: table;
    }

    &::before {
      margin-bottom: -0.003em;
    }

    &::after {
      margin-top: 0.003em;
    }
  }

  ${breakpoint("md")`
    flex-direction: column;
    row-gap: 12px;
  `}
`;

const DonateText = styled.span`
  display: none;
  font-size: 14px;

  ${breakpoint("md")`
    display: block;
  `}
`;

const Amount = styled.span`
  font-size: 28px;
  font-weight: 900;
`;

const Explanation = styled.div`
  line-height: 1.4;

  ${breakpoint("md")`
    text-align: center;
  `}
`;

const LightText = styled.div`
  color: hsl(0, 0%, 40%);
  font-size: 14px;
`;

const Footer = styled.div`
  border-top: 1px solid hsl(0, 0%, 90%);
  display: flex;
  font-family: DMSans, Arial, sans-serif;
  font-weight: ${({ selected }) => (selected ? 900 : 500)};
  transition: border-color 200ms ease-in-out;
  width: 100%;

  ${breakpoint("md")`
    margin-top: auto;
  `}
`;

const Radio = styled.div`
  align-items: center;
  column-gap: 6px;
  display: flex;
  margin-top: 12px;
  position: relative;

  input {
    --size: 1.45em;

    appearance: none;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.green};
    color: currentColor;
    display: grid;
    font: inherit;
    height: var(--size);
    margin: 0;
    place-content: center;
    width: var(--size);

    &::before {
      --size: 0.9em;

      border-radius: 50%;
      box-shadow: inset 1em 1em ${({ theme }) => theme.colors.green};
      content: "";
      height: var(--size);
      transform: scale(0);
      transition: transform 200ms ease-in-out;
      width: var(--size);
    }

    &:checked::before {
      transform: scale(1);
    }
  }
`;

export default AmountOption;
