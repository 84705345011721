import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import TopSpin from "../components/TopSpin";
import {
  Button,
  CheckBox,
  Fieldset,
  Form,
  Label,
  Legend,
  Main,
  Radio,
  TextField,
  FormError,
} from "../components";
import axios from "axios";
import Tooltip from "../components/Tooltip";
import AmountOption from "../components/AmountOption";

const donationTypes = [
  {
    amount: 50,
    description: "",
  },
  {
    amount: 30,
    description: "",
    highlight: true,
  },
  {
    amount: 15,
    description: "",
  },
  {
    amount: "anders",
    display: "no",
  },
];
const ageTypes = [
  {
    value: "0-17",
    label: "<18",
  },
  {
    value: "18-25",
    label: "18-25",
  },
  {
    value: "26-40",
    label: "26-40",
  },
  {
    value: "41-100",
    label: ">40",
  },
];
const gender = [
  {
    value: "male",
    label: "Man",
  },
  {
    value: "female",
    label: "Vrouw",
  },
  {
    value: "other",
    label: "Anders",
  },
];

const Donation = ({ navigation }) => {
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [state, updateState] = React.useState({
    donation: "",
    callme: 0,
    errorMessage: "",
    minimumContributionError: "",
    selectedTrack: JSON.parse(localStorage.getItem("selectedTrack")),
    motivation: localStorage.getItem("motivation") || "",
    isFormValid: false,
    hasError: false,
    noAmountError: false,
    city: "",
    agegroup: "",
    gender: "",
  });
  const { previous } = navigation;
  const donationRef = useRef();

  const onSubmit = (data) => {
    setFormSubmitted(true);

    if (!state.donation) {
      setFormSubmitted(false);

      // scroll to donationRef element
      window.scrollTo({
        top: donationRef.current.offsetTop,
      });

      updateState({
        ...state,
        noAmountError: true,
      });

      return;
    }

    if (formSubmitted) {
      return;
    }

    if (!state.donation) {
      updateState({
        ...state,
        noAmountError: true,
      });
    } else {
      TopSpin.doClick({
        name: "doneer-knop",
        type: "navigation",
        chapter_1: "Doneren",
      });

      const bodyFormData = new FormData();

      bodyFormData.append("source", "vepa");
      bodyFormData.append("track_artist", state.selectedTrack.artist);
      bodyFormData.append("track_title", state.selectedTrack.title);
      bodyFormData.append("motivation", state.motivation);
      bodyFormData.append("firstname", data.firstname);
      bodyFormData.append("middlename", data.middlename);
      bodyFormData.append("lastname", data.lastname);
      bodyFormData.append("donation_amount", state.donation);

      if (state.gender) {
        bodyFormData.append("gender", state.gender);
      }

      if (state.agegroup) {
        bodyFormData.append("agegroup", state.agegroup);
      }

      if (state.callme === true) {
        bodyFormData.append("calling_allowed", state.callme);
        bodyFormData.append("phonenumber", data.telnumber);
      }

      if (data.city) {
        bodyFormData.append("city", data.city);
      }

      axios
        .post(
          (process.env.REACT_APP_BACKEND_URL ||
            "https://vepa-beheer-test.npo3fm.nl") + "/api/request/add",
          bodyFormData,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((response) => {
          updateState({
            ...state,
            isFormValid: true,
            hasError: false,
          });

          const data = response.data;
          const form = document.getElementById("payment_transport_form");

          form.action = data.url;

          for (const prop in data.payload) {
            let inp = document.createElement("input");

            inp.type = "hidden";
            inp.name = prop;
            inp.value = data.payload[prop];

            form.appendChild(inp);
          }

          setTimeout(function () {
            form.submit();
          }, 2000);
        })
        .catch(() => {
          updateState({
            ...state,
            hasError: true,
          });
        });
    }
  };

  const handlePermission = (event) => {
    updateState({
      ...state,
      callme: event.target.checked,
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "donation") {
      document.getElementById("anderbedrag").value = "";

      updateState({
        ...state,
        donation: event.target.value,
        minimumContributionError: "",
      });
    }

    if (event.target.name === "anderbedrag") {
      (
        document.querySelector('input[name="donation"]:checked') || {}
      ).checked = false;

      updateState({
        ...state,
        donation: event.target.value,
        minimumContributionError: "",
      });

      if (event.target.value < 5) {
        updateState({
          ...state,
          minimumContributionError:
            "Het minimale donatiebedrag voor het aanvragen van een plaat is €5,-",
        });
      }
    }

    if (event.target.name === "callme") {
      updateState({
        ...state,
        callme: event.target.checked,
      });
    }

    if (event.target.name === "city") {
      updateState({
        ...state,
        city: event.target.checked,
      });
    }

    if (event.target.name === "agegroup") {
      updateState({
        ...state,
        agegroup: event.target.value,
      });
    }

    if (event.target.name === "gender") {
      updateState({
        ...state,
        gender: event.target.value,
      });
    }
  };

  return !state.isFormValid ? (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Main.Container>
          <Main.Row>
            <Main.Column>
              <Fieldset id="donatie" variant="donatie">
                {state.noAmountError ? (
                  <FormError>Kies een bedrag</FormError>
                ) : (
                  ""
                )}
                <Legend>Hoeveel wil je doneren?</Legend>

                <AmountOptionsContainer ref={donationRef}>
                  {donationTypes
                    .filter((item) => item.display !== "no")
                    .map((item, index) => (
                      <AmountOption
                        amount={item.amount}
                        description={item.description}
                        highlight={item.highlight}
                        id={`donation-${item.amount}`}
                        key={index}
                        name="donation"
                        onChange={handleChange}
                        selected={parseInt(state.donation) === item.amount}
                      />
                    ))}
                </AmountOptionsContainer>

                <TextField
                  name="anderbedrag"
                  type="number"
                  step="1"
                  min="5"
                  placeholder="Kies zelf een bedrag"
                  hideLabel="true"
                  onClick={handleChange}
                  onChange={handleChange}
                  autoComplete="chrome-off"
                  icon="euro"
                  onKeyPress={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "-" ||
                      e.key === "," ||
                      e.key === "."
                    ) {
                      e.preventDefault();
                    }
                  }}
                  ref={register({
                    pattern: {
                      value: /^\d+$/,
                      message: "Dit bedrag lijkt niet geldig",
                    },
                  })}
                  errors={errors.anderbedrag}
                >
                  Kies zelf een bedrag
                </TextField>

                {state.minimumContributionError && (
                  <FormError>{state.minimumContributionError}</FormError>
                )}
              </Fieldset>
            </Main.Column>
          </Main.Row>
          <hr />
          <Main.Row>
            <Main.Column>
              <Fieldset id="personalinformation" variant="personal">
                <Legend level={2}>Persoonlijke gegegevens</Legend>
                <TextField
                  name="firstname"
                  ref={register({ required: "Vul je voornaam in" })}
                  errors={errors.firstname}
                  onChange={handleChange}
                  tooltip="Vul hier je voornaam in"
                >
                  Voornaam *
                </TextField>

                <TextField
                  name="middlename"
                  ref={register()}
                  errors={errors.middlename}
                  onChange={handleChange}
                  tooltip="Vul hier je tussenvoegsel in"
                >
                  Tussenvoegsel
                </TextField>

                <TextField
                  name="lastname"
                  ref={register({ required: "Vul je achternaam in" })}
                  errors={errors.lastname}
                  onChange={handleChange}
                  tooltip="Vul hier je achternaam in"
                >
                  Achternaam *
                </TextField>

                <div className="flexwrapper">
                  <Label htmlFor="agegroup">Leeftijdscategorie</Label>
                  <Tooltip content="Om iedereen tijdens de uitzendingen zoveel mogelijk aan bod te laten komen is het voor ons interessant om te weten tot welke leeftijdscategorie jij behoort" />
                  {ageTypes.map((item, index) => (
                    <Radio
                      key={index}
                      id={"age" + item.value}
                      value={item.value}
                      name="agegroup"
                      button="true"
                      display={item.display}
                      onChange={handleChange}
                    >
                      {item.label}
                    </Radio>
                  ))}
                </div>
                <div className="flexwrapper">
                  <Label htmlFor="gender">Geslacht</Label>
                  <Tooltip content="Om iedereen tijdens de uitzendingen zoveel mogelijk aan bod te laten komen is het voor ons interessant om te weten tot welk geslacht jij behoort" />
                  {gender.map((item, index) => (
                    <Radio
                      key={index}
                      id={"gender" + item.value}
                      value={item.value}
                      name="gender"
                      button="true"
                      display={item.display}
                      onChange={handleChange}
                    >
                      {item.label}
                    </Radio>
                  ))}
                </div>
                {state.callme ? (
                  <TextField
                    name="city"
                    value={state.city}
                    onChange={handleChange}
                    onClick={handleChange}
                    tooltip="Vul hier je woonplaats in"
                    ref={register({ required: "Vul je woonplaats in" })}
                    errors={errors.city}
                  >
                    Woonplaats *
                  </TextField>
                ) : (
                  <TextField
                    name="city"
                    value={state.city}
                    onChange={handleChange}
                    onClick={handleChange}
                    tooltip="Vul hier je woonplaats in"
                  >
                    Woonplaats
                  </TextField>
                )}

                <CheckBox name="callme" onChange={handleChange}>
                  Je mag mij bellen in de uitzending
                </CheckBox>
                {state.callme ? (
                  <TextField
                    name="telnumber"
                    onChange={handleChange}
                    ref={register({
                      required: "Vul je telefoonnummer in",
                      pattern: {
                        value: /^[\d\-+]/i,
                        message:
                          "Dit telefoonnummer lijkt niet geldig. Graag een geldig telefoonnummer invoeren.",
                      },
                    })}
                    errors={errors.telnumber}
                    tooltip="Vul hier je telefoonummer in"
                  >
                    Telefoonnummer *
                  </TextField>
                ) : (
                  ""
                )}
              </Fieldset>

              <Fieldset id="permissions">
                <Legend hidden>Vinkjes om je goedkeuring te vragen</Legend>
                <CheckBox
                  name="permission"
                  onclick={handlePermission}
                  ref={register({
                    required:
                      "Je moet akkoord gaan met bovenstaande privacyverklaring om je nummer aan te vragen",
                  })}
                  errors={errors.permission}
                >
                  <p>
                    Ja, ik ben 16 jaar of ouder en ik geef toestemming voor het
                    verwerken van mijn persoonsgegevens zoals vermeld in
                    de&nbsp;
                    <a
                      href="https://over.npo.nl/organisatie/privacy-en-security"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacyverklaring
                    </a>
                  </p>
                </CheckBox>
              </Fieldset>
            </Main.Column>
          </Main.Row>

          {state.hasError ? (
            <Main.Row>
              <Main.Column>
                <FormError>
                  Fout bij opslaan, bekijk de opgegeven velden
                </FormError>
              </Main.Column>
            </Main.Row>
          ) : (
            ""
          )}
          <Main.Row>
            <Main.Column>
              <Button.Group>
                <Button onClick={previous}>Vorige</Button>
                <Button.Green type="submit" disabled={formSubmitted}>
                  Doneer aan Metakids
                </Button.Green>
              </Button.Group>
            </Main.Column>
          </Main.Row>
        </Main.Container>
      </Form>
    </>
  ) : (
    <Main.Container>
      <Main.Row $variant="withSpacingBorderLeft">
        <Main.Column size={{ md: 1 / 2 }}>
          <Main.Title level={2}>Een ogenblik geduld...</Main.Title>
          <Main.Text>Je wordt nu doorverwezen naar de donatiepagina.</Main.Text>
        </Main.Column>
      </Main.Row>
      <Main.Row $variant="withSpacingBorderLeft">
        <form
          method="post"
          noValidate="novalidate"
          id="payment_transport_form"
        />
      </Main.Row>
    </Main.Container>
  );
};

const AmountOptionsContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-auto-flow: row;
  margin-top: 40px;

  ${breakpoint("md")`
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  `}
`;

export default Donation;
