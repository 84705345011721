import React, { forwardRef } from "react";
import {
  ConditionalWrapper,
  FormError,
  Icon,
  Label,
  Tooltip,
  VisuallyHidden,
} from "./";
import styled from "styled-components";

const TextField = forwardRef((props, ref) => {
  return (
    <FormGroup style={props.inlineStyle} icon={props.icon}>
      <ConditionalWrapper
        condition={props.hideLabel}
        wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
      >
        <Label htmlFor={props.name} hideLabel={props.hideLabel}>
          {props.children}
        </Label>
      </ConditionalWrapper>

      <StyledInput
        id={props.name}
        name={props.name}
        type={props.type}
        min={props.min}
        pattern={props.pattern}
        step={props.step}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        onChange={props.onChange}
        icon={props.icon}
        iconPos={props.iconPos}
        aria-invalid={
          props.errors && props.errors.type === "required" ? "true" : "false"
        }
        ref={ref}
        isAlternative={props.isAlternative}
      />

      <ConditionalWrapper
        condition={props.icon}
        wrapper={() => (
          <StyledIconWrapper
            iconPos={
              props.iconPos && props.iconPos === "right" ? "right" : null
            }
          >
            <Icon icon={props.icon} color="#000" />
          </StyledIconWrapper>
        )}
      >
        {""}
      </ConditionalWrapper>

      <ConditionalWrapper
        condition={props.tooltip}
        wrapper={() => <Tooltip content={props.tooltip} />}
      >
        {""}
      </ConditionalWrapper>

      <ConditionalWrapper
        condition={props.errors}
        wrapper={() => <FormError>{props.errors.message}</FormError>}
      >
        {""}
      </ConditionalWrapper>
    </FormGroup>
  );
});

const FormGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  + * {
    margin-top: 1.5em;
  }

  ${(props) =>
    props.icon === "euro" &&
    `
	position: relative;
	`}
`;

const StyledInput = styled.input.attrs((props) => ({
  type: props.type || "text",
}))`
  flex: 1 0 100%;
  width: 100%;
  font-family: ${({ theme }) => theme.type.fontFamily};
  font-size: 16px;
  line-height: 26px;
  border: solid 2px ${({ theme }) => theme.colors.black};
  border-radius: 5px;
  padding: 0.5em;

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.colors.focus};
  }

  ${(props) => {
    if (props.isAlternative) {
      return `
      color: ${props.theme.form.dark};
      background-color: ${props.theme.form.light};
	`;
    } else {
      return `
		  color: ${props.theme.form.dark};
  		background-color: ${props.theme.form.light};
   	`;
    }
  }}

  ${(props) =>
    props.icon &&
    props.iconPos !== "right" &&
    `
	padding-left: 2em;
	`}

  ${(props) =>
    props.icon &&
    props.iconPos === "right" &&
    `
	padding-right: 2em;
	`}
`;

const StyledIconWrapper = styled.span`
  align-items: center;
  display: flex;
  left: 0.5em;
  position: absolute;
  top: 22px;
  transform: translateY(-50%);

  ${(props) =>
    props.iconPos === "right" &&
    `
	left: unset;
	right: .5em;
	`}
`;

FormGroup.defaultProps = {
  hideLabel: false,
};

StyledInput.defaultProps = {
  type: "text",
};

export default TextField;
