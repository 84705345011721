import React from "react";
import { bool, func } from "prop-types";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import theme from "../theme";

const Burger = ({ open, setOpen, ...props }) => {
  const isExpanded = !!open;

  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};

const StyledBurger = styled.button`
  position: ${({ open }) => (open ? "fixed" : "absolute")};
  top: 2em;
  left: ${({ open }) => (open ? "12rem" : "2em")};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100;

  &:focus {
    outline: none;
  }

  ${breakpoint("sm")`
    top: 3em;
  `}

  span {
    width: 2rem;
    height: 0.25rem;
    background: ${({ theme, open }) =>
      open ? theme.colors.typeInverse : theme.colors.type};
    border-radius: 10px;
    transition: all 200ms linear;
    position: relative;
    transform-origin: 0.375rem;
    background-color: ${({ open }) =>
      open ? theme.colors.black : theme.colors.white};

    :first-child {
      width: ${({ open }) => (open ? "1rem" : "2.1rem")};
      transform: ${({ open }) =>
        open
          ? "rotate(-35deg) translate(-.375rem, .375rem)"
          : "scale(1) translateY(0)"};
    }

    :nth-child(3) {
      width: ${({ open }) => (open ? "1rem" : "1.9rem")};
      transform: ${({ open }) =>
        open
          ? "rotate(35deg) translate(-.375rem, -.375rem)"
          : "scale(1) translateY(0)"};
    }
  }
`;

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;
