import React from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { Button, Icon, Heading, Main, TextField } from "../components";
import "../assets/css/modal.css";
import TopSpin from "./TopSpin";

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

export const TrackInput = (props) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [state, updateState] = React.useState({
    isModalOpen: false,
    enabled: false,
  });

  const watchFields = watch();

  const onSubmit = (data) => {
    data.trackId = null;
    data.cover = null;
    props.onAddTrack(data);
    closeModal();
  };

  const closeModal = () => {
    updateState({
      ...state,
      isModalOpen: false,
    });
  };

  const openModal = () => {
    updateState({
      ...state,
      isModalOpen: true,
    });

    TopSpin.doClick({ name: "custom_entry_popup_open", type: "action" });
  };

  const checkInput = () => {
    updateState({
      ...state,
      enabled: watchFields.title.length > 0 && watchFields.artist.length > 0,
    });
  };

  return (
    <>
      <form>
        <Main.Text center>
          <Button.Text onClick={openModal}>
            Mijn liedje staat er niet tussen
          </Button.Text>
        </Main.Text>
      </form>

      <Modal
        isOpen={state.isModalOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        className="Modal"
        overlayClassName="Overlay"
      >
        <Button onClick={closeModal}>
          <Icon icon="cross" />
        </Button>

        <Heading level={3}>Vrije keuze toevoegen</Heading>

        <Main.Text>
          Kan je een nummer niet vinden in de lijst? Voeg dan je nummer toe als
          vrije keuze!
        </Main.Text>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            name="title"
            ref={register({ required: "Titel is verplicht" })}
            errors={errors.artist}
            placeholder="Vul hier de titel in"
            onChange={checkInput}
            hideLabel={true}
            icon="vinyl"
            iconPos="right"
            isAlternative={true}
          >
            Titel
          </TextField>

          <TextField
            name="artist"
            ref={register({ required: "Artiest is verplicht" })}
            errors={errors.artist}
            placeholder="Vul hier de artiest in"
            onChange={checkInput}
            hideLabel={true}
            icon="microphone"
            iconPos="right"
            isAlternative={true}
          >
            Artiest
          </TextField>

          <Button.Green
            disabled={!state.enabled}
            type="submit"
            onClick={() =>
              TopSpin.doClick({
                name: "custom_entry_popup_submit",
                type: "action",
              })
            }
          >
            VOEG TOE ALS VRIJE KEUZE
          </Button.Green>
        </form>
      </Modal>
    </>
  );
};

export default TrackInput;
