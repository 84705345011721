import React from "react";
import styled from "styled-components";

const Logo = () => (
  <Image src="/images/3fm-serious-request-logo-2022.webp" alt="NPO 3FM Logo" />
);

const Image = styled.img`
  display: block;
  width: 114px;
  height: auto;
`;

export default Logo;
