import React from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import breakpoint from "styled-components-breakpoint";
import { Link } from "react-router-dom";

export default function Header(props) {
  const { children, ...restProps } = props;
  return <Wrapper {...restProps}>{children}</Wrapper>;
}

const Wrapper = styled.header`
  position: relative;
  z-index: 10;
  display: flex;
  width: 100%;
  color: hsl(0, 0%, 100%);
  background-color: hsl(0, 0%, 0%);
  font-family: ${({ theme }) => theme.type.fontFamily};

  &:before {
    content: " ";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 75px;
    z-index: 1;
    background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  }

  ${breakpoint("md")`
    height: 27rem;
  `}
`;

Header.Image = function HeaderImage(props) {
  const { children, ...restProps } = props;
  return (
    <Image {...restProps}>
      {children}
      <DjsImage src="/images/djs.webp" />
    </Image>
  );
};

const Image = styled.div`
  display: flex;
  position: relative;
  background: url("/images/header.webp") center center / cover no-repeat;
  width: 100%;
  height: 100%;
  min-height: 480px;
  overflow: hidden;
`;

const DjsImage = styled.img`
  display: none;

  ${breakpoint("md")`
    position: absolute;
    display: block;
    right: 70px;
    height: 100%;
  `}
`;

Header.Sizer = function HeaderSizer(props) {
  const { children, ...restProps } = props;
  return <Sizer {...restProps}>{children}</Sizer>;
};

const Sizer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  z-index: 2;
`;

Header.Container = function HeaderContainer(props) {
  const { children, ...restProps } = props;
  return <Container {...restProps}>{children}</Container>;
};

const Container = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.L};
  max-width: 64rem;
`;

Header.Row = function HeaderRow(props) {
  const { children, ...restProps } = props;
  return <Row {...restProps}>{children}</Row>;
};

const Row = styled(Grid)`
  flex-direction: column;
  gap: 20px;
`;

Header.Column = function HeaderColumn(props) {
  const { children, ...restProps } = props;
  return <Grid.Unit {...restProps}>{children}</Grid.Unit>;
};

Header.Steps = function HeaderSteps(props) {
  const { children, ...restProps } = props;
  return <Steps {...restProps}>{children}</Steps>;
};

const Steps = styled.span`
  padding: ${({ theme }) => theme.spacing.XS};
  color: ${({ theme }) => theme.colors.greenType};
  background: linear-gradient(
    150.82deg,
    rgb(255, 255, 40) -9.11%,
    rgb(53, 211, 39) 92.16%
  );
  font-weight: 700;
  text-transform: uppercase;
`;

Header.Title = function HeaderTitle(props) {
  const { children, ...restProps } = props;
  return <Title {...restProps}>{children}</Title>;
};

const Title = styled.h1`
  position: relative;
  font-size: 1.25rem;
  line-height: 1;
  text-transform: uppercase;
  text-shadow: 0 2px 4px hsla(0, 0%, 0%, 50%);
  padding-left: 20px;

  &::before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    bottom: 0;
    width: 2px;
    height: 1em;
    background-color: ${({ theme }) => theme.colors.green};
    left: 0;
  }

  ${breakpoint("md")`
    font-size: 2rem;
  `}
`;

Header.Highlight = styled.span`
  color: #fff;
`;

Header.HeaderLink = function HeaderHeaderLink(props) {
  const { children, ...restProps } = props;
  return <HeaderLink {...restProps}>{children}</HeaderLink>;
};

const HeaderLink = styled(Link)`
  color: currentColor;
  text-decoration: none;
  display: flex;

  &:hover,
  &:focus {
    text-decoration: underline;
    transition: 200ms ease-in;
  }

  &.logo {
    font-size: 2rem;
    line-height: 1;

    ${breakpoint("md")`
      font-size: 3rem;
    `}

    svg {
      width: auto;
    }
  }
`;

Header.LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5em;
`;

Header.TagLine = (props) => {
  const { children, ...restProps } = props;
  return <HeaderTagLine {...restProps}>{children}</HeaderTagLine>;
};

const HeaderTagLine = styled.span`
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  width: 280px;

  ${breakpoint("md")`
    width: 100%;
  `}
`;
