import React from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import breakpoint from "styled-components-breakpoint";
import { Heading, List } from "./index";

export default function Footer(props) {
  const { children, ...restProps } = props;
  return <Wrapper {...restProps}>{children}</Wrapper>;
}

const Wrapper = styled.footer`
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding: ${({ theme }) => theme.spacing.L};
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.type.fontFamily};
`;

Footer.Container = function FooterContainer(props) {
  const { children, ...restProps } = props;
  return <Container {...restProps}>{children}</Container>;
};

const Container = styled.div`
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  display: flex;
  flex: 1;
  flex-direction: column;
  justiy-content: center;
  max-width: 64rem;
  margin: 0 auto;
  font-size: calc(0.85rem + 0.16vw);

  > * + * {
    margin-top: 1.5em;
  }
`;

Footer.Row = function FooterRow(props) {
  const { children, ...restProps } = props;
  return <Row {...restProps}>{children}</Row>;
};

const Row = styled(Grid)`
  > * + * {
    margin-top: 1.5em;

    ${breakpoint("md")`
	  margin-top: 0;
	`}
  }

  &:last-child {
    align-items: flex-start;
  }

  ${(props) =>
    props.$variant === "withSpacingBorderLeft" &&
    breakpoint("md")`
      >  * {
        &:first-child {
          padding-right: 1.5em;
        }

        + * {
          padding-left: 1.5em;
          border-left: 2px solid;
        }
      }
    }
  `}
`;

Footer.Column = function FooterColumn(props) {
  const { variant, children, ...restProps } = props;
  return (
    <Column variant={variant} {...restProps}>
      {children}
    </Column>
  );
};

const Column = styled(Grid.Unit)`
  > * + * {
    margin-top: 0.75em;
  }

  &.inline {
    * + * {
      margin-left: 0.5em;
      padding-left: 0.5em;
      border-left: 1px solid ${({ theme }) => theme.colors.type};
    }

    ${breakpoint("md")`
      text-align: right;
    `}
  }

  ${(props) =>
    props.$variant === "sociallinks" &&
    `
	order: -1;
	`}

  ${(props) =>
    props.$variant === "sociallinks" &&
    breakpoint("md")`
	order: 0;
	`}
`;

Footer.FooterLink = function FooterFooterLink(props) {
  const { children, ...restProps } = props;
  return <FooterLink {...restProps}>{children}</FooterLink>;
};

const FooterLink = styled.a`
  color: currentColor;
  text-decoration: none;
  transition: color 0.2s cubic-bezier(0.195, 0.4, 0.45, 0.785) 0s;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.green};
  }

  &.inline {
    flex: 1;
  }

  &.logo {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 3rem;
    line-height: 1;

    svg {
      width: auto;
    }
  }
`;

Footer.List = function FooterList(props) {
  const { children, ...restProps } = props;
  return <List {...restProps}>{children}</List>;
};

Footer.ListItem = function FooterListItem(props) {
  const { children, ...restProps } = props;
  return <List.ItemVert {...restProps}>{children}</List.ItemVert>;
};

Footer.Title = function FooterTitle(props) {
  const { children, ...restProps } = props;
  return <Heading {...restProps}>{children}</Heading>;
};

Footer.Text = function FooterText(props) {
  const { children, ...restProps } = props;
  return <Text {...restProps}>{children}</Text>;
};

const Text = styled.p`
  margin-bottom: 0;
`;

Footer.Div = function FooterDiv(props) {
  const { children, ...restProps } = props;
  return <Div {...restProps}>{children}</Div>;
};

const Div = styled.div`
  margin: 0;
`;
