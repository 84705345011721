import React from "react";
import { Card, Heading, List } from "../components";

const Recommended = React.memo(
  ({ tracks, clickhandler }) => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const tracksList = shuffleArray(tracks)
      .slice(0, 4)
      .map((item, index) => (
        <List.Item
          className="recommended"
          key={index}
          onClick={() => clickhandler(item.dalet)}
        >
          <Card title={item.title} artist={item.artist} buttonToggle="add" />
        </List.Item>
      ));

    return (
      <>
        {tracksList && (
          <>
            <Heading level={2}>Dit vroegen anderen aan</Heading>
            <List className="recommended">{tracksList}</List>
          </>
        )}
      </>
    );
  },
  () => true
); // For some reason Reacts thinks we always get different props, so ignore them all

export default Recommended;
