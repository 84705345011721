export const icons = {
  cross:
    "M12 9.366l8.107-8.107a1.864 1.864 0 012.634-.001 1.864 1.864 0 010 2.635L14.634 12l8.107 8.107a1.864 1.864 0 010 2.635 1.864 1.864 0 01-2.634-.001L12 14.634l-8.107 8.107a1.864 1.864 0 01-2.635 0 1.864 1.864 0 01.001-2.634L9.366 12 1.259 3.893a1.864 1.864 0 01-.001-2.634 1.864 1.864 0 012.635 0L12 9.366z",
  euro:
    "M14.856 3.75a6.444 6.444 0 014.08 1.27l1.476 1.157 2.313-2.952-1.475-1.156A10.096 10.096 0 0014.856 0C9.086 0 5.837 3.743 4.713 8.25H1.275v2.25h3.056c-.046.499-.079.999-.079 1.5s.033 1.001.08 1.5H1.274v2.25h3.438C5.838 20.257 9.086 24 14.856 24c2.305.06 4.562-.67 6.394-2.07l1.475-1.155-2.314-2.951-1.475 1.156a6.446 6.446 0 01-4.08 1.27 6.146 6.146 0 01-6.262-4.5h6.931V13.5H8.093c-.12-.996-.12-2.004 0-3h7.432V8.25h-6.93a6.146 6.146 0 016.261-4.5z",
  facebook:
    "M24 12.073C24 5.403 18.629 0 12 0S0 5.404 0 12.073C0 18.099 4.388 23.093 10.125 24v-8.437H7.077v-3.49h3.048v-2.66c0-3.026 1.79-4.697 4.532-4.697 1.314 0 2.687.236 2.687.236v2.97H15.83c-1.49 0-1.955.93-1.955 1.885v2.266h3.328l-.532 3.49h-2.796V24C19.612 23.094 24 18.099 24 12.073z",
  info:
    "M12 0C5.373 0 0 5.375 0 12c0 6.629 5.373 12 12 12s12-5.371 12-12c0-6.625-5.373-12-12-12zm0 5.323a2.032 2.032 0 110 4.063 2.032 2.032 0 010-4.063zm2.71 12.29a.58.58 0 01-.581.581H9.871a.58.58 0 01-.581-.581v-1.161a.58.58 0 01.581-.581h.581v-3.097h-.581a.58.58 0 01-.581-.58v-1.162c0-.32.26-.58.581-.58h3.097c.32 0 .58.26.58.58v4.839h.581a.58.58 0 01.581.581v1.161z",
  instagram:
    "M12.003 5.847A6.143 6.143 0 005.85 12a6.143 6.143 0 006.152 6.153A6.143 6.143 0 0018.155 12a6.143 6.143 0 00-6.152-6.153zm0 10.153c-2.2 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.8 4-4 4zM19.84 5.595c0 .798-.642 1.435-1.435 1.435a1.435 1.435 0 111.435-1.435zm4.075 1.457c-.091-1.923-.53-3.626-1.939-5.029C20.575.62 18.872.181 16.95.084c-1.981-.112-7.919-.112-9.9 0-1.917.091-3.62.53-5.027 1.934C.614 3.42.18 5.124.084 7.046c-.112 1.982-.112 7.92 0 9.902.091 1.923.53 3.626 1.939 5.029 1.408 1.403 3.105 1.842 5.027 1.939 1.981.112 7.919.112 9.9 0 1.922-.091 3.625-.53 5.027-1.939 1.403-1.403 1.842-3.106 1.939-5.029.112-1.981.112-7.915 0-9.896zm-2.56 12.022a4.05 4.05 0 01-2.28 2.282c-1.58.626-5.328.482-7.073.482-1.746 0-5.499.139-7.073-.482a4.05 4.05 0 01-2.281-2.282c-.626-1.58-.482-5.328-.482-7.074 0-1.746-.14-5.5.482-7.074a4.05 4.05 0 012.28-2.282c1.58-.626 5.328-.482 7.074-.482 1.745 0 5.498-.139 7.073.482a4.05 4.05 0 012.28 2.282c.627 1.58.482 5.328.482 7.074 0 1.746.145 5.5-.482 7.074z",
  microphone:
    "M3.88 18.734l1.267 1.246 10.017-8.749c.8.135 1.629.058 2.395-.236L11.12 4.667a4.346 4.346 0 00-.239 2.353l-8.902 9.845 1.266 1.246-.633.624a3.037 3.037 0 00-.919 2.18c0 .825.326 1.599.918 2.181a3.146 3.146 0 002.22.904c.838 0 1.625-.321 2.217-.904l5.705-5.606c.847-.833 2.324-.831 3.169 0 .425.417.656.969.656 1.558 0 .589-.232 1.142-.656 1.558l-1.267 1.246.633.622 1.268-1.245a3.037 3.037 0 00.918-2.181 3.04 3.04 0 00-.918-2.181c-1.182-1.164-3.25-1.164-4.437 0l-5.705 5.606c-.846.834-2.322.832-3.169 0a2.176 2.176 0 010-3.115l.635-.624zm4.438-5.607l.633.623-.633.623-.633-.622.633-.624zm2.535-2.491l.634.623-1.901 1.868-.634-.622 1.901-1.869zm8.241-.623l1.901-1.869a4.354 4.354 0 000-6.231l-.634-.622a4.537 4.537 0 00-6.339 0l-1.901 1.868a4.425 4.425 0 00-.57.686l6.844 6.729c.247-.162.482-.347.699-.561z",
  plus:
    "M22.286 9.429H14.57V1.714C14.571.768 13.804 0 12.857 0h-1.714c-.947 0-1.714.768-1.714 1.714V9.43H1.714C.768 9.429 0 10.196 0 11.143v1.714c0 .947.768 1.714 1.714 1.714H9.43v7.715c0 .946.767 1.714 1.714 1.714h1.714c.947 0 1.714-.768 1.714-1.714V14.57h7.715c.946 0 1.714-.767 1.714-1.714v-1.714c0-.947-.768-1.714-1.714-1.714z",
  search:
    "M23.707 22.299l-5.768-5.774a10.091 10.091 0 002.29-6.41C20.23 4.53 15.7 0 10.115 0 4.525 0 0 4.53 0 10.115c0 5.584 4.524 10.114 10.114 10.114 2.437 0 4.665-.857 6.404-2.29l5.773 5.768a.998.998 0 001.415 0 .99.99 0 000-1.408zm-13.592-4.084c-4.47 0-8.106-3.63-8.106-8.1s3.637-8.106 8.106-8.106c4.47 0 8.106 3.637 8.106 8.106 0 4.47-3.637 8.1-8.106 8.1z",
  snapchat:
    "M23.946 18.407c-.244.57-1.277.988-3.158 1.28-.096.13-.176.688-.305 1.123-.076.26-.263.416-.568.416H19.9c-.44 0-.9-.203-1.821-.203-1.243 0-1.672.283-2.637.965-1.023.724-2.005 1.348-3.47 1.284-1.483.11-2.72-.792-3.416-1.284-.97-.687-1.398-.965-2.636-.965-.884 0-1.441.221-1.821.221-.379 0-.526-.23-.583-.424-.126-.43-.206-.996-.305-1.13-.97-.151-3.156-.532-3.211-1.508a.5.5 0 01.416-.519c3.262-.537 4.73-3.886 4.792-4.028l.01-.024c.175-.353.214-.649.116-.879-.236-.558-1.26-.758-1.69-.928-1.111-.439-1.266-.943-1.2-1.29.114-.6 1.018-.971 1.547-.723.418.196.79.295 1.103.295a.957.957 0 00.467-.102c-.095-1.685-.333-4.092.267-5.436C7.412.998 10.767.723 11.758.723l.473-.004c2.445 0 4.794 1.255 5.94 3.827.6 1.343.364 3.74.268 5.438.074.04.204.09.403.1.3-.014.647-.112 1.034-.293.285-.134.675-.116.96.002h.001c.445.16.724.48.731.838.009.457-.4.852-1.213 1.173-.1.04-.22.078-.348.118-.46.146-1.153.366-1.342.81-.097.23-.059.526.116.879a.34.34 0 01.01.024c.062.142 1.53 3.49 4.793 4.028.301.05.523.37.362.744z",
  spotify:
    "M12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0zm4.873 17.656c-.204 0-.33-.062-.518-.174-3.02-1.82-6.532-1.897-10.002-1.185-.188.048-.435.126-.576.126a.753.753 0 01-.764-.765c0-.498.295-.735.658-.813 3.963-.876 8.013-.798 11.468 1.268.295.189.47.358.47.798 0 .44-.344.745-.736.745zm1.301-3.174c-.251 0-.42-.111-.595-.203-3.024-1.79-7.534-2.511-11.545-1.423-.232.063-.358.126-.576.126a.94.94 0 01-.939-.938c0-.518.252-.862.75-1.002 1.346-.377 2.72-.658 4.733-.658 3.14 0 6.174.779 8.564 2.201.392.233.547.533.547.954a.938.938 0 01-.939.943zm1.5-3.687c-.251 0-.406-.063-.624-.189C15.605 8.55 9.445 8.056 5.458 9.17c-.174.049-.392.126-.624.126-.639 0-1.128-.498-1.128-1.142 0-.658.407-1.03.842-1.156 1.704-.499 3.61-.736 5.686-.736 3.532 0 7.234.736 9.939 2.313.377.218.624.518.624 1.094 0 .658-.532 1.127-1.123 1.127z",
  twitter:
    "M21.533 7.112c.015.213.015.426.015.64 0 6.502-4.949 13.994-13.995 13.994A13.9 13.9 0 010 19.538c.396.046.777.061 1.188.061a9.85 9.85 0 006.106-2.101 4.927 4.927 0 01-4.599-3.412c.305.046.61.076.93.076.441 0 .883-.06 1.294-.167A4.92 4.92 0 01.975 9.168v-.061a4.954 4.954 0 002.223.624 4.915 4.915 0 01-2.193-4.096c0-.914.244-1.752.67-2.483A13.981 13.981 0 0011.817 8.3a5.553 5.553 0 01-.122-1.127 4.917 4.917 0 014.92-4.92c1.415 0 2.695.595 3.593 1.554A9.684 9.684 0 0023.33 2.62a4.905 4.905 0 01-2.163 2.711A9.86 9.86 0 0024 4.569a10.573 10.573 0 01-2.467 2.543z",
  vinyl:
    "M14.73.316C8.288-1.189 1.822 2.828.316 9.27c-1.505 6.442 2.512 12.908 8.954 14.414 6.443 1.505 12.909-2.512 14.414-8.954C25.189 8.289 21.173 1.823 14.73.316zM1.896 7.226a11.211 11.211 0 016.512-5.803l2.443 7.193a3.59 3.59 0 00-2.08 1.858L1.896 7.226zM12 15.167a3.166 3.166 0 11-.001-6.333A3.166 3.166 0 0112 15.167zm3.591 7.411l-2.443-7.193a3.6 3.6 0 002.082-1.858l6.873 3.249a11.201 11.201 0 01-6.512 5.801v.001z",
  whatsapp:
    "M20.405 3.487A11.803 11.803 0 0011.995 0C5.437 0 .102 5.336.102 11.893c0 2.094.546 4.14 1.585 5.946L0 24l6.305-1.655a11.857 11.857 0 005.684 1.446h.006C18.546 23.791 24 18.455 24 11.898c0-3.177-1.35-6.16-3.595-8.41zm-8.41 18.3a9.867 9.867 0 01-5.036-1.376l-.359-.215-3.74.98.997-3.647-.236-.375a9.852 9.852 0 01-1.51-5.261c0-5.448 4.435-9.884 9.889-9.884a9.797 9.797 0 016.986 2.898c1.864 1.87 3.01 4.35 3.005 6.991 0 5.454-4.548 9.89-9.996 9.89zm5.421-7.403c-.295-.15-1.757-.868-2.03-.964-.274-.102-.472-.15-.67.15-.198.3-.766.964-.943 1.167-.171.199-.348.225-.643.075-1.746-.873-2.893-1.558-4.044-3.535-.306-.525.305-.488.873-1.623.096-.199.048-.37-.027-.52-.075-.15-.67-1.613-.916-2.207-.241-.579-.487-.498-.67-.51-.171-.01-.37-.01-.567-.01a1.1 1.1 0 00-.793.37c-.274.3-1.04 1.018-1.04 2.48 0 1.463 1.066 2.877 1.211 3.075.15.198 2.095 3.198 5.079 4.49 1.885.814 2.625.883 3.568.744.573-.086 1.757-.718 2.003-1.414.247-.697.247-1.291.172-1.415-.07-.133-.268-.208-.563-.353z",
  youtube:
    "M23.499 6.203a3.016 3.016 0 00-2.122-2.136C19.505 3.563 12 3.563 12 3.563s-7.505 0-9.377.504A3.016 3.016 0 00.502 6.203C0 8.087 0 12.017 0 12.017s0 3.93.502 5.814a2.97 2.97 0 002.121 2.102c1.872.505 9.377.505 9.377.505s7.505 0 9.377-.505a2.97 2.97 0 002.122-2.102C24 15.947 24 12.017 24 12.017s0-3.93-.501-5.814zM9.545 15.586V8.449l6.273 3.568-6.273 3.569z",
};
