import React from "react";
import { Legend, SelectAsync } from "../components";
import axios from "axios";

const TrackSearch = (props) => {
  const [state, updateState] = React.useState({
    cachedTracks: JSON.parse(
      localStorage.getItem("tracks")
        ? localStorage.getItem("tracks")
        : JSON.stringify({
            date: null,
            tracks: [],
          })
    ),
    recommendedTracks: JSON.parse(
      localStorage.getItem("recommended_tracks")
        ? localStorage.getItem("recommended_tracks")
        : JSON.stringify({
            date: null,
            tracks: [],
          })
    ),
  });
  let tracks = [];

  React.useEffect(() => {
    let items = state.cachedTracks;

    if (
      items.tracks.length === 0 ||
      new Date().valueOf() - items.date > 7200000
    ) {
      axios
        .get(
          (process.env.REACT_APP_BACKEND_URL ||
            "https://vepa-beheer-test.npo3fm.nl") + "/api/tracks"
        )
        .then((response) => {
          const tracksObj = {
            date: new Date().valueOf(),
            tracks: response.data.map((item) => {
              return {
                trackId: item.id,
                title: item.title,
                artist: item.artist,
                year: item.release_year,
                dalet: item.daletcode,
              };
            }),
          };

          localStorage.setItem("tracks", JSON.stringify(tracksObj));

          updateState({
            ...state,
            cachedTracks: tracksObj,
          });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          (process.env.REACT_APP_BACKEND_URL ||
            "https://vepa-beheer-test.npo3fm.nl") + "/api/featured-tracks"
        )
        .then((response) => {
          const tracksObj = {
            date: new Date().valueOf(),
            tracks: response.data.map((item) => {
              return {
                trackId: item.id,
                title: item.title,
                artist: item.artist,
                year: item.release_year,
                dalet: item.daletcode,
              };
            }),
          };

          localStorage.setItem("recommended_tracks", JSON.stringify(tracksObj));

          updateState({
            ...state,
            recommendedTracks: tracksObj,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const parseTracks = () => {
    tracks = [];
    state.cachedTracks.tracks.map((item) =>
      tracks.push({
        value: item.trackId,
        label: item.artist + " - " + item.title,
      })
    );
  };

  const filterTracks = (inputValue) => {
    if (!inputValue) {
      return [];
    }

    let search = inputValue.toLowerCase();
    let items = tracks.filter((item) =>
      item.label.toLowerCase().includes(search)
    );

    return items;
  };

  const loadOptions = (inputValue, callback) => {
    let items = state.cachedTracks;

    updateState({
      ...state,
      cachedTracks: items,
    });

    parseTracks();

    setTimeout(() => {
      callback(filterTracks(inputValue));
    }, 1000);
  };

  const handleChange = (selectedOption) => {
    if (!selectedOption) {
      return;
    }

    const track = state.cachedTracks.tracks.find(
      (x) => x.trackId === parseInt(selectedOption.value)
    );

    if (track) {
      props.onSelectTrack(track);
    }
  };

  const formatLabel = (data, { context }) => {
    switch (context) {
      case "value":
        return data.label.replace("<strong>", "").replace("</strong>", "");
      case "menu":
        return <span dangerouslySetInnerHTML={{ __html: data.label }} />;
      default:
        return "";
    }
  };

  return (
    <form>
      <Legend htmlFor="search">Kies een nummer*</Legend>
      <SelectAsync
        ref={props.selectRef}
        id="search"
        name="search"
        placeholder="Zoek op artiest of titel"
        cacheOptions
        onChange={handleChange}
        noOptionsMessage={() => null}
        loadOptions={loadOptions}
        onKeyUp={filterTracks(this)}
        formatOptionLabel={formatLabel}
        AutoFocus
      />
    </form>
  );
};

export default TrackSearch;
