import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Button, Icon, VisuallyHidden } from "../components";

const CardContent = (props) => {
  const buttonToggle = props.buttonToggle;
  let button;
  let buttonToggleText =
    props.buttonToggle === "add"
      ? "deze track toevoegen"
      : "gekozen track verwijderen";

  if (buttonToggle) {
    button = (
      <CardButton>
        <Button.AddRemove
          variant={props.buttonToggle}
          onClick={props.removeTrack}
        >
          <Icon icon="cross" />
          <VisuallyHidden>{buttonToggleText}</VisuallyHidden>
        </Button.AddRemove>
      </CardButton>
    );
  }

  return (
    <CardText>
      {button}
      <p>
        {props.title}
        <br />
        <span>{props.artist}</span>
      </p>
    </CardText>
  );
};

const Card = (props) => {
  return (
    <CardWrapper>
      <CardContent
        title={props.title}
        artist={props.artist}
        buttonToggle={props.buttonToggle}
        removeTrack={props.removeTrack}
      />
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${breakpoint("md")`
    flex-direction: column;
  `}
`;

const CardText = styled.div`
  display: flex;
  flex: 1 1 calc(100% - 4rem);
  align-items: center;
  color: ${({ theme }) => theme.colors.type};

  ${breakpoint("md")`
    margin-top: .5em;
  `}

  p {
    order: -1;
    margin-bottom: 0;
    padding-left: 0.375em;
    line-height: 1.5;
    font-weight: 700;
    text-transform: uppercase;

    span {
      font-weight: initial;
      text-transform: initial;
    }

    ${breakpoint("md")`
      order: unset;
    `}
  }
`;

const CardButton = styled.div`
  margin-left: auto;
  margin-right: 0;
  padding: 0.1875em;
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoint("md")`
    margin-left: 0;
  `}
`;

Card.defaultProps = {
  width: 350,
  title: "Template - Card Title",
  location: "Location label",
  description: "Template description textbox",
};

export default Card;
