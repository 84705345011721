import React from "react";
import styled from "styled-components";

const CharityLogo = () => (
  <Image src="/images/charity-logo.svg" alt="Metakids Logo" />
);

const Image = styled.img`
  display: block;
  width: 100px;
  height: auto;
`;

export default CharityLogo;
