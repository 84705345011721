import React from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { Icon } from "./index";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon="search" />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    color: "hsl(200, 22%, 14%)",
    backgroundColor: "hsl(0, 0%, 100%)",
    border: "2px solid hsl(200, 22%, 14%) !important",
    borderRadius: 0,
    padding: ".1875em",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "hsl(200, 22%, 14%)",
  }),
  indicatorSeparator: () => ({
    // none of react-select's styles are passed to indicatorSeparator
  }),
  input: (provided, state) => ({
    ...provided,
    color: "hsl(200, 22%, 14%)",
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: "0.75em",
    borderRadius: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 20,
    color: "hsl(0, 0%, 0%)",
    backgroundColor: state.isFocused ? "hsl(115, 69%, 49%)" : "transparent",
  }),
  singleValue: (provided, state) => {
    const color = "hsl(200, 22%, 14%)";
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, color, opacity, transition };
  },
};

export const SelectAsync = (props, ref) => (
  <AsyncSelect
    ref={ref}
    components={{ DropdownIndicator }}
    classNamePrefix="react-select"
    styles={customStyles}
    {...props}
  />
);

export default React.forwardRef(SelectAsync);
