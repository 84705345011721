const colors = {
  type: "hsl(0, 0%, 0%)",
  typeInverse: "hsl(0, 0%, 100%)",
  green: "#35d327",
  greenType: "hsl(0, 0%, 0%)",
  grey: "hsl(0, 0%, 90%)",
  greyType: "hsl(0, 0%, 0%)",
  greenInactive: "rgb(53, 211, 39, 0.5)",
  black: "rgb(11, 17, 22)",
  white: "hsl(0, 0%, 100%)",
  focus: "rgb(77, 144, 254)",
};

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,

  alfa: "20em",
  bravo: "36em",
  charlie: "48em",
  delta: "64em",
  echo: "70em",
};

const type = {
  fontFamily: "Mikro,DMSans,Arial,sans-serif",
};

const spacing = {
  XS: ".25em",
  S: ".5em",
  M: "1em",
  L: "1.5em",
  XL: "2em",
};

const form = {
  light: "hsl(0, 0%, 100%)",
  dark: "rgb(11, 17, 22)",
  border: "2px solid",
  borderColorValid: "hsl(115, 69%, 49%)",
  borderColorInvalid: "hsl(0, 100%, 50%)",
};

export default {
  colors,
  breakpoints,
  form,
  spacing,
  type,
};
