const TopSpin = {
  initialize: () => {
    const NpoTag = () => {
      (function (document, tag) {
        const scriptTag = document.createElement(tag),
          firstScriptTag = document.getElementsByTagName(tag)[0];

        scriptTag.src = "https://npotag.npo-data.nl/prod/npo-tag.min.js";
        scriptTag.async = true;
        firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

        scriptTag.addEventListener("load", function () {
          const tag = window.npotag.newTag(
            {
              brand: "npo3fm",
              brand_id: process.env.REACT_APP_BRAND_ID || 631149,
              platform: "site",
              platform_version: "0.1.0",
              environment: process.env.REACT_APP_TOPSPIN_ENV || "dev",
            },
            [
              window.npotag.newATInternetPlugin(),
              window.npotag.newGovoltePlugin(),
            ]
          );

          const tracker = window.npotag.newPageTracker(
            tag,
            window.pageTrackers
          );
          tracker.pageView();
          window.tag = tag;
          window.tracker = tracker;
        });
      })(document, "script");
    };

    return {
      NpoTag,
    };
  },
  doCount: (page) => {
    window.pageTrackers = {
      page: page,
      chapter_1: "seriousrequest",
      chapter_2: "vraageenplaataan",
    };

    if (typeof window.npotag === "undefined") {
      TopSpin.initialize().NpoTag();
    } else {
      // we need to manually create a new pageTracker and track the pageView
      const tracker = window?.npotag?.newPageTracker(
        window.tag,
        window.pageTrackers
      );
      tracker.pageView();
    }
  },

  doClick: ({ name, type, chapter_1 }) => {
    if (window.tracker) {
      window.tracker.click({
        click_name: name,
        click_type: type,
        chapter_1: chapter_1 || "seriousrequest",
      });
    }
  },
};

export default TopSpin;
