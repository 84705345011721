import React from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import breakpoint from "styled-components-breakpoint";
import { Heading } from "./index";

export default function Main(props) {
  const { children, ...restProps } = props;
  return <Wrapper {...restProps}>{children}</Wrapper>;
}

const Wrapper = styled.main`
  margin-bottom: 32px;

  > * + * {
    margin-top: 1.5em;
  }
`;

Main.BackgroundBlur = function MainRow(props) {
  const { children, ...restProps } = props;
  return <BackgroundBlur {...restProps}>{children}</BackgroundBlur>;
};

const BackgroundBlur = styled.div`
  position: relative;
  z-index: 1;
  color: #fff;
  background: hsla(0, 0%, 27%, 0.8);

  @supports (backdrop-filter: blur()) {
    background: hsla(0, 0%, 27%, 0.3);
    backdrop-filter: blur(13px);
  }
`;

Main.Container = function MainRow(props) {
  const { children, ...restProps } = props;
  return <Container {...restProps}>{children}</Container>;
};

const Container = styled.div`
  position: relative;
  z-index: 2;
  padding: ${({ theme }) => theme.spacing.L};
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;

  > * + * {
    margin-top: 1.5em;
  }

  ${(props) =>
    props.wide &&
    `
	  max-width: 64rem;
	`}
`;

Main.Row = function MainRow(props) {
  const { variant, children, ...restProps } = props;
  return (
    <Row variant={variant} {...restProps}>
      {children}
    </Row>
  );
};

const Row = styled(Grid)`
  > * + * {
    margin-top: 0.75em;
    ${breakpoint("md")`
 		margin-top: 0;
 		padding-left: .75em;
      `}
  }

  ${(props) =>
    props.$variant === "withSpacingBorderLeft" &&
    breakpoint("md")`
      >  * {
        &:first-child {
          padding-right: 1.5em;
        }

        + * {
          padding-left: 1.5em;
          border-left: 2px solid;
        }
      }
    }
  `}

  ${(props) =>
    props.$variant === "withSpacing" &&
    breakpoint("md")`
      >  * {
        &:first-child {
          padding-right: 1.5em;
        }

        &:last-child {
          padding-left: 1.5em;
        }
      }
    }
  `}
`;

Main.Column = function MainRow(props) {
  const { children, ...restProps } = props;
  return <Column {...restProps}>{children}</Column>;
};

const Column = styled(Grid.Unit)`
  > * + * {
    margin-top: 0.75em;
  }
`;

Main.Title = function MainRow(props) {
  const { children, ...restProps } = props;
  return <Heading {...restProps}>{children}</Heading>;
};

Main.Text = function MainRow(props) {
  const { children, ...restProps } = props;
  return <Text {...restProps}>{children}</Text>;
};

const Text = styled.p`
  ${({ center }) =>
    center &&
    `
      text-align: center;
    `}

  a {
    color: ${({ theme }) => theme.colors.green};
  }
`;

Main.Anchor = ({ children, href }) => {
  return <Anchor href={href}>{children}</Anchor>;
};

const Anchor = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
`;
