import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Heading = (props) => {
  const { level, light, ...rest } = props;
  return <StyledHeading as={`h${level}`} light={light} {...rest} />;
};

const StyledHeading = styled.h1`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ light, theme }) =>
    light ? theme.colors.white : theme.colors.type};
  font-family: ${({ theme }) => theme.type.fontFamily};
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-transform: uppercase;

  ${(props) =>
    props.svgIcon &&
    `
    svg {
      padding: .1875em;
      margin-right: .1875em;
      background-color: white;
	  width: auto;
	  height: auto;
	}
	`}
`;

Heading.propTypes = {
  type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
};

Heading.defaultProps = {
  level: 1,
};

export default Heading;
